// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-thought-piece-js": () => import("./../../../src/templates/thoughtPiece.js" /* webpackChunkName: "component---src-templates-thought-piece-js" */),
  "component---src-templates-whats-new-js": () => import("./../../../src/templates/whatsNew.js" /* webpackChunkName: "component---src-templates-whats-new-js" */)
}

